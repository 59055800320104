import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";


import IconUsers from "../components/iconUsers";


const Navigation = () => {
  return (
    <div className="navigation">
      <div className="navigation__logo-box"></div>
      <nav className="navigation__nav">
        <ul className="navigation__list">
          <li className="navigation__item navigation__item--active">
            <NavLink
              to="/"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              className={({ isActive }) =>
                isActive
                  ? "navigation__link--active navigation__link"
                  : "navigation__link"
              }
            >
              <IconUsers />
              <span>Guests</span>
            </NavLink>
          </li>
         
        </ul>
      </nav>
    </div>
  );
};
export default Navigation;
