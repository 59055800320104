import React,  { StrictMode } from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";




//***************************[ STYLES]************************* */
import "./sass/main.scss";
import "react-datepicker/dist/react-datepicker.css";


//***************************[ ROUTES]************************* */
import Routes from "./Routes";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <Routes />
  </StrictMode>
);