import React from "react";
import { NavLink } from "react-router-dom";

// ICONS

import IconSettings from "../components/iconSettings";
import IconUser from "../components/iconUser";
import IconBell from "../components/iconBell";
import Avatar from "../assets/images/avatar_00.jpg";

const Header = () => {
  return (
    <div className="header">
      <div className="header__icons">
        <IconBell />
        <IconSettings />
        <div className="header__img-box">
          <img src={Avatar} alt="IMG" />
          <span>Edwin Muvandi</span>
        </div>
      </div>
    </div>
  );
};
export default Header;
