import React from "react";
import { Link } from "react-router-dom";

import Navigation from "./navigation";
import Header from "./header";
import Card from "../components/Card";

import { ReactComponent as Chevron } from ".././assets/svg/chevron-right.svg";

const Layout = ({children}) => {


  return (
    <>
      <Navigation />
      <Header />
      {children}
    </>
  );
};
export default Layout;
