import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { getUsers } from "../api/users";

import Layout from "./layout";
import FilterComponent from "../components/filterComponent";

const Events = () => {
  const [users, setUsers] = useState([]);
  const [loadingEvents, setLoadingEvents] = useState(true);

  const init = () => {
    setLoadingEvents(true);
    getUsers()
      .then((res) => {
        console.log("events", res.data);
        setUsers(res.data);
      })
      .catch((error) => {
        console.log("::Error::", error);
      })
      .finally(() => {
        setLoadingEvents(false);
      });
  };

  useEffect(() => {
    init();
  }, []);
  return (
    <Layout>
      <div className="content">
        <div className="content__breadcrum">
          <h1 className=" heading-secondary">Guests <span>({users.length})</span></h1>
        </div>

        <div class=" claims__table">
          <div class=" accounts__table-header">
            <div class="accounts__table-cell">Name</div>
            <div class="accounts__table-cell">Phone</div>
            <div class="accounts__table-cell">Drink</div>
            <div class="accounts__table-cell">message</div>
            <div class="accounts__table-cell">Date</div>
          </div>
          {users.map((user, i) => (
            <div class="accounts__table-row">
              <div class=" accounts__table-cell">
                <p className="accounts__table-name">{user.name}</p>
              </div>
              <div class=" accounts__table-cell">{user.phone}</div>
              <div class=" accounts__table-cell">{user.drink}</div>
              <div class=" accounts__table-cell">{user.message}</div>
              <div class=" accounts__table-cell"> {moment(user.createdAt).format('LLL')}  </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};
export default Events;
